<template>
  <div class="page">
    <div class="page-title">
      <img @click="$router.push('/')" src="@/assets/images/back.svg">
      {{$route.meta.routeTitle}}
    </div>
    <div class="paragr">
      Порядок заключения договоров с юридическими лицами
    </div>
    <div class="paragr">
      Все юридические лица, образующие коммунальные отходы, обязаны заключать договор на оказание услуг по обращению с твердыми коммунальными отходами с региональным оператором Эта норма закреплена статьей 24.7 Федерального закона № 89-ФЗ «Об отходах производства и потребления».
    </div>
    <div class="paragr">
      Для упрощенной заявки нажмите кнопку «отставить заявку» ниже.
    </div>
    <div class="paragr">
      Ваше обращение будет рассмотрено в кратчайшие сроки, с вами свяжется ваш менеджер.
    </div>
    <div class="paragr">
      Отправить заявку на заключение договора просто - достаточно заполнить форму, указав ИНН, контактный номер телефона и email, адрес места нахождения и название вывески.
    </div>

    <ECButton
      label="Отправить заявку"
      @click="isShowModalCreateContractTKO = true"
    />

    <div class="tko-modal" :class="{active: isShowModalCreateContractTKO}">
      <ECModalCreateContractTKO
        @close="isShowModalCreateContractTKO = false"
      />
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import ECModalCreateContractTKO from '@/components/modals/ECModalCreateContractTKO.vue'

const isShowModalCreateContractTKO = ref(false)

</script>

<style scoped lang="scss">
  h4{
    margin-bottom: 16px;
  }
  .par, ul, a{
    margin-bottom: 36px;
    font-size: 16px;
    line-height: 28px;
    font-family: "TT Norms Pro Regular";

    a{
      color: $ec-green;
    }

    li{
      margin-bottom: 8px;
      display: flex;
      align-items: center;
      img{
        margin-right: 10px;
      }
      &:last-child{
        margin-bottom: 0;
      }
    }
  }

.page-title{
  display: flex;
  align-items: flex-start;
  margin-bottom: 30px;
  padding-left: 0;
  img{
    margin-right: 10px;
    margin-top: 3px;
    cursor: pointer;
  }
}
.paragr{
  margin-bottom: 28px;
  font-size: 16px;
  line-height: 28px;
  font-family: 'TT Norms Pro Regular';
}

.tko-modal{
  position: fixed;
  z-index: 99999;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  opacity: 0;
  visibility: hidden;
  transition: ease .3s;

  &.active{
    opacity: 1;
    visibility: visible;
  }
}
</style>
